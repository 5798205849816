import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { PRERELEASE_BLOCKED_COUNTRIES } from '~/app/lib/privacy';
import { BYPASS_COUNTRY_BLOCKING } from '~/config.js';
import { useSelector } from '~/app/lib/store/redux';
import { useAppRouter } from '~/app/lib/router2';

import { useItemContext } from './ItemPageContext';

export const useCountryBlocked = () => {
  const item = useItemContext();
  const country = useSelector(selectUserCountry);
  const query = useAppRouter().getQuery();

  // special query param for privacy team testing purposes
  if (query[BYPASS_COUNTRY_BLOCKING] !== undefined) {
    return false;
  }

  if (
    item.data.item.type === 'album' &&
    item.data.item.isPrerelease &&
    !item.data.item.isDraft &&
    !item.data.item.userCanEdit
  ) {
    return PRERELEASE_BLOCKED_COUNTRIES.includes(country);
  }

  return false;
};
