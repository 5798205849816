import { LinksLocalized } from '../../utils/resolveLinksForCountry/lib/localizeLinks';
import { SelectedPrimaryOwnerAccount } from '../lib/selectors';
import { MappedAlbum } from '../../songwhipApi/mapper/types';
import ApiError from '~/app/lib/errors/ApiError';
import { ReduxAction } from '../types';
import { ItemTypes } from '~/types';
import { OrchardBrands } from '../../songwhipApi/types';

export enum AlbumsActionTypes {
  FETCH_ALBUM_START = 'FETCH_ALBUM_START',
  FETCH_ALBUM_SUCCESS = 'FETCH_ALBUM_SUCCESS',
  FETCH_ALBUM_ERROR = 'FETCH_ALBUM_ERROR',
}

export interface FetchAlbumStartAction extends ReduxAction {
  type: AlbumsActionTypes.FETCH_ALBUM_START;
  albumId: number;
}

export interface FetchAlbumSuccessAction extends ReduxAction {
  type: AlbumsActionTypes.FETCH_ALBUM_SUCCESS;
  payload: MappedAlbum;
}

export interface FetchAlbumErrorAction extends ReduxAction {
  type: AlbumsActionTypes.FETCH_ALBUM_ERROR;
  albumId: number;
  error: Error | ApiError;
}

export interface StoredAlbum extends Omit<MappedAlbum, 'artists'> {
  artistIds: number[];
}

export interface SelectedAlbum {
  type: ItemTypes.ALBUM;
  id: number;
  artistId: number;
  artistIds: number[];
  artistName: string;
  artistPagePath: string;
  error: Error | ApiError | undefined;
  hasLinksForUserCountry: boolean;

  /**
   * The original image found by songwhip-lookup
   */
  originalImage: string | undefined;

  /**
   * The resolved image favouring custom image falling back to default
   */
  image: string | undefined;

  isDraft?: boolean;
  isLoading: boolean;
  isOwned: boolean;
  isSmePage: boolean;
  pageBrand?: OrchardBrands;
  isPrerelease: boolean | undefined;
  isStale: boolean;
  isShallow: boolean;
  links: LinksLocalized | undefined;
  name: string;
  pagePath: string;
  userCanEdit: boolean | undefined;
  ownedByAccountIds: number[] | undefined;
  primaryOwnerAccount: SelectedPrimaryOwnerAccount | undefined;
  config: MappedAlbum['config'];
  releaseDate: string | undefined;
  upc: string | undefined;
  customLinks: MappedAlbum['customLinks'];
  catalogProductId?: number;
  updatedAtTimestamp: number | undefined;
}

export type AlbumAction =
  | FetchAlbumStartAction
  | FetchAlbumSuccessAction
  | FetchAlbumErrorAction;
