import { removePathSuffix } from './utils';

type PageObject = {
  path: string;
  regex: RegExp;
};

/**
 * WARNING: if you add/remove/change files in `pages/*` without
 * the checksum of this file changing then it risks sometimes not
 * compiling with latest pages, even in production deploys!
 *
 * It's still not clear what the route cause of this is, but
 * the workaround is to bump this version number comment
 * whenever you make a change :(
 *
 * VERSION: 12 <-- change this to bust babel cache
 */

// @ts-ignore
// eslint-disable-next-line no-undef
// REVIEW: do we really need to inline all public paths?
const staticFiles = preval`
  const readDirSync = require('fs').readdirSync;
  const glob = require('glob');
  const path = require('path');

  const pagesDir = path.resolve(__dirname, '../../../pages/');
  const publicDir = path.resolve(__dirname, '../../../public/');

  const pagesFiles = glob.sync('**/**', { cwd: pagesDir, nodir: true });
  const publicFiles = glob.sync('*', { cwd: publicDir, nodir: true });

  exports.pages = pagesFiles
    .map((file) => file.replace(/.(js|mdx|ts|tsx)$/, ''))
    .map((file) => file.replace(/\\/?index$/, ''))
    .filter((name) => !name.startsWith('_'))
    .filter((name) => !name.startsWith('api'))
    .filter((name) => !name.endsWith('.json'))

    .map((file) => {
      const path = '/' + file;
      const pattern = '^' + path.replace(/\\[[^\\]]+\\]/g, '([^/]+)') + '$';

      return {
        path,
        pattern,
      };
    })

    // put dynamic params last ordered by specificity
    .sort((item1, item2) => {
      const item1DynamicParamMatches = item1.path.match(/\\[/g);
      const item1TotalParams = item1DynamicParamMatches ? item1DynamicParamMatches.length : 0;

      const item2DynamicParamMatches = item2.path.match(/\\[/g);
      const item2TotalParams = item2DynamicParamMatches ? item2DynamicParamMatches.length : 0;

      return item2TotalParams > item1TotalParams ? -1 : 1;
    })

    .map(({ path, pattern }) => [path, pattern]);

  exports.public = publicFiles.map((file) => '/' + file);
`;

// for some reason preval exports RegExps as empty objects
// so at runtime we're converting string patterns into regex
const pages: PageObject[] = staticFiles.pages.map(([path, pattern]) => ({
  path,
  regex: new RegExp(pattern),
}));

const toNextPage = (url: string) => {
  // remove hash and search
  const path = removePathSuffix(url);

  const isExcluded =
    // avoid stop level /public static assets being confused w/ pages
    staticFiles.public.indexOf(path) > -1 ||
    // exclude 'special' nextjs dirs
    /^\/(static|_next|api)\//.test(path);

  if (isExcluded) return;

  // find a page that matches the pathname
  const page = pages.find((page) => page.regex.test(path));
  if (!page) return;

  const matches = path.match(page.regex);

  return {
    path: page.path,
    params: matches?.slice(1),
  };
};

export default toNextPage;
