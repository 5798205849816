import { useMemo } from 'react';

import { toReleasesSectionPreset } from '~/app/components/ItemPage/sections/ReleasesSection/presets';
import { toCarouselSectionPreset } from '~/app/components/ItemPage/sections/CarouselSection/presets';
import { toDescriptionSectionPreset } from '~/app/components/ItemPage/sections/BlockText/presets';
import { toVideosSectionPreset } from '~/app/components/ItemPage/sections/VideosSection/presets';
import { toMerchSectionPreset } from '~/app/components/ItemPage/sections/MerchSection/presets';
import { toShowsSectionPreset } from '~/app/components/ItemPage/sections/ShowsSection/presets';
import { CarouselItem } from '~/app/components/ItemPage/sections/CarouselSection/types';
import { PageSectionTypes } from '~/app/components/ItemPage/sections/types';
import { SelectedArtist } from '~/app/lib/store/artists/types';
import getPublicEndpoint from '~/app/lib/getPublicEndpoint';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import { useSelector } from '~/app/lib/store/redux';
import { State } from '~/app/lib/store/types';
import { useI18n } from '~/app/lib/i18n';

import {
  toArtistIconsPreset,
  toSocialLinksSectionPreset,
} from '~/app/components/ItemPage/sections/IconLinksSection/presets';

import {
  selectUserCountry,
  selectUserGeolocation,
} from '~/app/lib/store/session/selectors';

import {
  ItemContext,
  PageSectionDefinition,
  PresetTypes,
} from '~/app/components/ItemPage/types';

import {
  toBuyLinksSectionPreset,
  toStreamLinksSectionPreset,
} from '~/app/components/ItemPage/sections/ItemLinks/presets';

import { ShareIds } from './useSharedArtistComponents/constants';

const useArtistItemContext = ({
  artist,
  withAllLinks = false,
  originalItemContext,
}: {
  artist: SelectedArtist | undefined;
  withAllLinks?: boolean;
  originalItemContext?: ItemContext;
}) => {
  const i18n = useI18n();
  const country = useSelector((state: State) => selectUserCountry(state));
  const geolocation = useSelector(selectUserGeolocation);

  return useMemo<ItemContext | undefined>(() => {
    if (!artist) return;

    const mostRecentAlbum = artist.albums?.[0];
    const defaultCarouselItems: CarouselItem[] = [];

    if (mostRecentAlbum) {
      defaultCarouselItems.push({
        text: `${mostRecentAlbum.name} · ${new Date(
          mostRecentAlbum.releaseDate
        ).getFullYear()}`,
        image: mostRecentAlbum.image,
        // use absolute url to ensure these links work under custom domains
        link: new URL(mostRecentAlbum.pagePath, getPublicEndpoint()).toString(),
      });
    }

    const addons = artist.config?.addons || {};

    addons[AddonTypes.THEME] = {
      showcaseBackground: true,
      ...addons[AddonTypes.THEME],
    };

    // The image quality is usually bad on these, if we can fix this
    // then we could consider enabling showing the latest video
    // on the second CarouselSection page.
    // const mostRecentVideo = artist.videos?.[0];
    // if (mostRecentVideo) {
    //   defaultCarouselItems.push({
    //     text: mostRecentVideo.title,
    //     image: mostRecentVideo.image.url,
    //     link: mostRecentVideo.link,
    //   });
    // }

    return {
      presets: {
        [PresetTypes.STREAM_LINKS]: toStreamLinksSectionPreset({
          title: i18n.t('item.actions.streamArtistOn'),
          navTitle: i18n.t('item.nav.stream'),

          // render all links so editor can see everything
          // no matter what country they are in
          country: withAllLinks ? 'ALL' : country,
        }),

        [PresetTypes.BUY_LINKS]: toBuyLinksSectionPreset({
          title: i18n.t('item.actions.buyTheirMusicOn'),
          navTitle: i18n.t('item.nav.buy'),
        }),

        [PresetTypes.DESCRIPTION]: toDescriptionSectionPreset({
          displayName: i18n.t('app.labels.description'),
          title: i18n.t('item.labels.aboutThisArtist'),
          text: artist.description,
          navTitle: i18n.t('item.nav.about'),
        }),

        [PresetTypes.SOCIAL_LINKS]: toSocialLinksSectionPreset({
          title: i18n.t('item.actions.followArtist'),
          navTitle: i18n.t('item.nav.follow'),
          shareId: ShareIds.SOCIAL_LINKS,
        }),

        [PresetTypes.ARTIST_ICONS]: toArtistIconsPreset(),

        // REVIEW: do we actually need presets for this? Instead we
        // could just render the preset directly into the layout.
        // The main issue with this is that if the user changes a
        // different section (eg. description) then the props
        // will be hard coded into the `config` instead of just
        // the `preset`. Presets mean that we can isolate default
        // props from the `config` persisted when any change is
        // made to the page layout/sections.
        [PresetTypes.RELEASES]: toReleasesSectionPreset({
          title: i18n.t('item.defaultReleasesTitle'),
          navTitle: i18n.t('item.nav.releases'),
          albums: artist.albums,
        }),

        [PresetTypes.VIDEOS]: toVideosSectionPreset({
          title: i18n.t('item.defaultVideosTitle'),
          navTitle: i18n.t('item.nav.videos'),
          items: artist.videos,
        }),

        [PresetTypes.SHOWS]: toShowsSectionPreset({
          title: i18n.t('item.defaultShowsTitle'),
          navTitle: i18n.t('item.nav.shows'),
          items: artist.shows,
          geolocation,
        }),

        [PresetTypes.MERCH]: toMerchSectionPreset({
          title: 'Official merch',
          navTitle: i18n.t('item.nav.merch'),
        }),

        [PresetTypes.CAROUSEL]: toCarouselSectionPreset({
          items: defaultCarouselItems,
        }),
      },

      data: {
        item: artist,
        custom: artist.config?.objects,
      },

      layout: {
        main: toLayout(artist),
      },

      addons,
      originalItemContext,
    };
  }, [artist, originalItemContext]);
};

const toLayout = (artist: SelectedArtist) => {
  return artist.config?.layout?.main || DEFAULT_LAYOUT;
};

const DEFAULT_LAYOUT: PageSectionDefinition[] = [
  {
    component: PageSectionTypes.PAGE_TITLE,
  },
  {
    preset: PresetTypes.ARTIST_ICONS,
  },
  {
    preset: PresetTypes.CAROUSEL,
  },
  {
    preset: PresetTypes.VIDEOS,
  },
  // REVIEW: Uncomment this once we want to release the Shows section
  // to everyone and include it by default
  // {
  //   preset: PresetTypes.SHOWS,
  // },
  {
    preset: PresetTypes.RELEASES,
  },
  {
    preset: PresetTypes.DESCRIPTION,
  },
];

export default useArtistItemContext;
